// Our main CSS
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */

// Our main CSS
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

import mitt from 'mitt'

import axios from 'axios'
window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
}



/**
 * 🦄: Now do your magic.
 */

let emitter = mitt()
window.emitter = emitter
import { createApp } from 'vue'
import JoinForm from './components/JoinForm.vue'
const vueForms = createApp({})
if (typeof(document.getElementById('vue-js-forms')) != 'undefined' && document.getElementById('vue-js-forms') != null) {
  vueForms.component('join-form', JoinForm)
  vueForms.mount('#vue-js-forms')
}

import FilterJobs from './components/FilterJobs.vue'
const vueFilterJobs = createApp({})
if (typeof(document.getElementById('js-job-feed')) != 'undefined' && document.getElementById('js-job-feed') != null) {
  vueFilterJobs.component('filter-jobs', FilterJobs)
  vueFilterJobs.mount('#js-job-feed')
}

import ExpandableFooter from './components/ExpandableFooter.vue'
const vueFooter = createApp({
  methods: {
    toggleJoinForm() {
      emitter.emit('join-form.open', { a: 'b' })
      return document.body.classList.add('overflow-hidden')
    }
  }
})
if (typeof(document.getElementById('js-vue-footer')) != 'undefined' && document.getElementById('js-vue-footer') != null) {
  vueFooter.component('expandable-footer', ExpandableFooter)
  vueFooter.mount('#js-vue-footer')
}

const largeHero = createApp({

  methods: {
    toggleJoinForm() {
      emitter.emit('join-form.open', { a: 'b' })
      return document.body.classList.add('overflow-hidden')
    }
  }

})

if (typeof(document.getElementById('js-large-hero')) != 'undefined' && document.getElementById('js-large-hero') != null) {
  largeHero.mount('#js-large-hero')
}

let vueJobEls = document.querySelectorAll('.js-vue-job')

if (vueJobEls.length) {
    vueJobEls.forEach(el => {
    let app = createApp({
      mounted () {
        let readMore = this.$refs.readMore
        if (typeof(readMore) != 'undefined' && readMore != null) {
            const onMouseMove = (e) =>{
                readMore.style.left = e.pageX + 'px'
                readMore.style.top = e.pageY + 'px'
            }
            document.addEventListener('mousemove', onMouseMove)
        }
      },
      methods: {
        toggleJoinForm() {
          emitter.emit('join-form.open', { a: 'b' })
          return document.body.classList.add('overflow-hidden')
        }
      }
    })
    app.mount(el)
  })
}



const menuApp = createApp({
  data: () => ({
    active: false,
    toggledItems: [],
    scrolled: false
  }),
  mounted() {
    document.addEventListener('scroll', () => {
        if (! this.scrolled && document.documentElement.scrollTop > window.screen.height) {
            this.scrolled = true
        }

        if (this.scrolled && document.documentElement.scrollTop === 0) {
            this.scrolled = false
        }
    })
  },
  methods: {
    toggle(){
        this.active = ! this.active
        if (this.active) {
            document.body.classList.add('overflow-hidden')
        }
        else {
            document.body.classList.remove('overflow-hidden')
        }
    },
    toggleJoinForm() {
      emitter.emit('join-form.open')
      return document.body.classList.add('overflow-hidden')
    }
  }

})

menuApp.mount('.js-main-menu')



if (document.getElementsByClassName('js-hero')) {
  import('./hero').then((module) => {
    module.default()
  })
}

if (document.getElementsByClassName('marquee-section')) {
  import('./marquee').then((module) => {
    module.default()
  })
}