<template>

    <div>
        <a class=" py-6 block" href="javascript:;" @click="expand">
            <div class="flex flex-wrap items-center">
                <h4 class="label uppercase">{{ title }}</h4>
                <div class="ml-auto footer-dict-expand self-center" ref="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5V19" :class="'stroke-' + color" stroke-linecap="square" stroke-linejoin="round"/>
                    <path d="M5 12H19" :class="'stroke-' + color" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>

            <div class="hidden mt-4 w-full" ref="content">
                <slot></slot>
            </div>

        </a>

    </div>

</template>

<script>

    export default {
        props: {
            title: String,
            content: String,
            color: String
        },
        methods: {
            expand() {
                this.$refs.content.classList.toggle('hidden');
                this.$refs.icon.classList.toggle('active');
            }
        }
    }

</script>