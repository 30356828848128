<template>

    <div>
        <div v-if="type === 'checkbox' " class="checkbox" :class="{'is-active' : fieldFocus, 'error' : validation.error && required}">
            <input :type="type" ref="input" :id="uuid + '_' + name" class="hidden" @change="checkboxUpdate();">
            <label class="flex text-tag checkbox" :for="uuid + '_' + name"><span class="check mt-1 md:mt-0"><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.73723 7.62044L3.82482 4.58394L4 3.9708L4.62774 4L6.93431 6.75912L5.73723 7.62044ZM2.26277 7.62044L1.06569 6.75912L3.37226 4L4 3.9708L4.17518 4.58394L2.26277 7.62044ZM3.47445 4.33577L0 3.45985L0.452555 2.0438L3.79562 3.38686L4 3.9708L3.47445 4.33577ZM4 3.9708L3.50365 3.59124L3.25547 0H4.74453L4.49635 3.59124L4 3.9708ZM4.52555 4.33577L4 3.9708L4.20438 3.38686L7.54745 2.0438L8 3.45985L4.52555 4.33577Z" fill="white"/></svg></span><slot></slot></label>
            <span class="text-tag inline-block mt-2 ml-5" :class="{ 'hidden' : ! validation.error}">{{ validation.message }}</span>
        </div>


        <div v-if="type === 'tags' " class="checkbox my-4" :class="{'is-active' : fieldFocus, 'error' : validation.error && required}">

          <div v-if="tags.length > 0" class="">
              <div class="flex mb-6 items-center">
                  <h3 class="text-24px md:text-32px leading-none text-darkness cubic form-section-title">My need is regarding</h3>
              </div>
              <div v-if="tags.length > 0" class="flex flex-wrap">
                  <a v-for="tag, idx in tags" :key="idx" href="javascript:;" :class="[{active: isTagSelected(tag.name)}, tag.shape]" @click="selectTag(tag.name)" class="text-14px link md:text-base mini-tag larger text-black uppercase mr-4 mb-4 border border-darkness">{{ tag.name }}</a>
              </div>
          </div>

        </div>

        <div v-if="type === 'textarea' " class="relative field-holder has-textarea" :class="{'is-active' : fieldFocus, 'error' : validation.error && required}">
            <label :class="labelClass">{{ label }}</label>
            <textarea :type="type"
                :class="{ 'error' : validation.error && required }"
                @focus="onFocus"
                @blur="onBlur"
                v-on:keyup="fieldUpdate($event);"
                class="input" ref="input"></textarea>
        </div>

        <div v-if="type === 'text' " class="relative field-holder" :class="{'is-active' : fieldFocus}">
            <label>{{ label }}</label>
            <input :type="type"
                :class="{ 'is-required error' : validation.error && required }"
                @focus="onFocus"
                @blur="onBlur"
                v-on:keyup="fieldUpdate($event);"
                class="input" ref="input">
            <div class="required-marker text-black absolute right-0" v-if="required">*</div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['label', 'name', 'modelValue', 'type', 'required', 'tags', 'labelClass'],
    emits: ['update', 'update:modelValue', 'focus', 'blur'],
    data: function (){
        return{
            selectedTags: [],
            fieldFocus: false,
            internalValue: false,
            uuid: null,
            validation: {
                error: false,
                message: 'Fyll ut ',
            }
        }
    },
    created() {

        this.uuid = ((length) => {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            return result;
        })(16);

    },
    methods: {
        checkboxUpdate(){
            let inputField = this.$refs.input;
            this.internalValue = inputField.checked

            this.$emit('update:modelValue', this.internalValue);
        },
        onFocus(e) {
            this.$emit('focus', this.name)
            this.fieldFocus = true
        },
        onBlur(e) {
            this.$emit('blur', this.name)
            this.fieldUpdate(e)
        },
        fieldUpdate(e){
            let inputField = this.$refs.input;
            if (e instanceof KeyboardEvent && e.type === 'keyup' && e.keyCode === 9) {
                return
            }
            if( inputField.value.length > 0 ){

                this.validation.error = false
                this.fieldFocus = true
                this.internalValue = inputField.value

            } else {
                this.internalValue = ''
                this.fieldFocus = false
                this.validation.error = true
            }

            this.$emit('update:modelValue', this.internalValue);
        },

        isTagSelected: function (name) {
            return this.selectedTags.includes(name)
        },
        selectTag: function (name) {
            if (this.selectedTags.includes(name)) { // If the specified filter already exists in the filters array, we should remove it.
                this.selectedTags.splice(name)
            }
            else { // Otherwise we append it to the filtered list.
              this.selectedTags.push(name)
            }
            this.$emit('update:modelValue', this.selectedTags);
        },

        isEmpty(field){

            let inputField = this.$refs.input;
            if( inputField.value.length > 0 ){

                return false;
            }

            return true;

        },

        formUpdate(){

        },


    }

}
</script>
