
<template>
    <div>
        <div class="holder">
            <div class="search mb-6">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="#1B1B1B" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.9996 14.0001L11.0996 11.1001" stroke="#1B1B1B" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>

                <input class="w-full" type="text" v-model="searchValue" placeholder="Search role, job or company..." />
            </div>
            <div class="flex mb-6 lg:mb-10 items-center pb-6 lg:pb-0 border-iceBlue border-b-2 border-dotted lg:border-none">
                <p class="uppercase text-13 font-light mr-12">{{ jobAmount }} jobb</p>
                <div class="flex md:justify-start space-x-6 text-13 font-light hidden lg:block" :class="tagBorder">
                    <a class="uppercase hover:bg-iceBlue hover:text-black" :class="{'bg-iceBlue text-black': ! filteredTags.length }" @click="resetFilter()"  href="javascript:;">Alla jobb</a>
                    <a class="uppercase hover:bg-iceBlue hover:text-black" :class="{'bg-iceBlue text-black': isFilterSelected('freelance')}" @click="toggleFilter('freelance')"  href="javascript:;">Frilans</a>
                    <a class="uppercase hover:bg-iceBlue hover:text-black" :class="{'bg-iceBlue text-black': isFilterSelected('permanent')}" @click="toggleFilter('permanent')"  href="javascript:;">Permanent</a>
                </div>
                <div class="ml-auto label uppercase" v-if="filledPositionsLink">
                    <a class="flex items-center arrow-holder right uppercase text-13 space-x-2 font-light hover:bg-iceBlue hover:text-black" :href="filledPositionsLink" >
                        <span>{{ filledPositionsText }}</span>
                        <span>→</span>
                    </a>
                </div>
            </div>
            <div class="flex mb-6 justify-between text-13 md:justify-start space-x-6 lg:hidden" :class="tagBorder">
                <a class="label uppercase" :class="{'bg-iceBlue text-black': ! filteredTags.length }" @click="resetFilter()"  href="javascript:;">
                    All jobs
                </a>
                <a class="label uppercase" :class="{'bg-iceBlue text-black': isFilterSelected('freelance')}" @click="toggleFilter('freelance')"  href="javascript:;">
                    Freelance
                </a>
                <a class="label uppercase" :class="{'bg-iceBlue text-black': isFilterSelected('permanent')}" @click="toggleFilter('permanent')"  href="javascript:;">
                    Permanent
                </a>
            </div>
            <div class="has-read-more">
                <div>
                    <a class="py-4 md:py-10 block list-hover border-t-2 border-iceBlue border-dotted group text-iceBlue hover:bg-iceBlue hover:text-black hover:px-6" :href="visibleItem.permalink" v-for="(visibleItem, idx) in visibleItems" :key="idx">
                        <div class="lg:flex">
                            <div>
                                <h3 class="heading-mobile-m lg:heading-m uppercase mb-2">{{ visibleItem.title }}</h3>
                                <div class="flex items-center">
                                    <p class="text-13 leading-none font-light uppercase">{{ visibleItem.sub_title }}</p>
                                </div>
                            </div>
                            <div class="mt-4 lg:mt-0 flex items-center ml-auto">
                                <span class=" rounded-32 group-hover:border-black bg-black text-iceBlue border-iceBlue border text-13 leading-none p-2 filled uppercase" v-if="visibleItem.position_type">{{ visibleItem.position_type }}</span>
                            </div>
                        </div>
                    </a>
                </div>
                <div ref="readMore" class="bg-black text-white  text-13  leading-none filled read-more uppercase">Read more</div>
            </div>
        </div>

        <div v-if="visibleItems.length == 0">

        </div>
    </div>
</template>

<script>
export default {
    props: ['items', 'text', 'tagBorder', 'filledPositionsText', 'filledPositionsLink'],

    data: () => ({
        searchValue: '',
        filteredTags: []
    }),
    mounted () {
        let readMore = this.$refs.readMore
        if (typeof(readMore) != 'undefined' && readMore != null) {
            const onMouseMove = (e) =>{
                readMore.style.left = e.pageX + 'px'
                readMore.style.top = e.pageY + 'px'
            }
            document.addEventListener('mousemove', onMouseMove)
        }
        if (window.location.hash) {
            let hash = window.location.hash.replace(/,/g, ' ');
            hash = hash.replace(/#/g, '');
            this.searchValue = hash;
        }
    },
    methods: {
        isFilterSelected: function (filter) {
            return this.filteredTags.includes(filter)
        },
        toggleFilter: function (filter) {
            this.filteredTags = []
            const index = this.filteredTags.indexOf(filter);
            if (index > -1) { // If the specified filter already exists in the filters array, we should remove it.
                this.filteredTags.splice(index)
            }
            else { // Otherwise we append it to the filtered list.
                this.filteredTags.push(filter);
            }
        },
        resetFilter: function () {
            this.filteredTags = []
        }
    },
    computed: {
        visibleItems: function () {
            return this.items.filter(item => {
                return this.filteredTags.length < 1 || this.filteredTags.includes(item.position_type)
            }).filter(item => {
                return item.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1 || item.sub_title.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1 || item.position_type.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1
            }).sort((a, b) => {
                return a.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) > b.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) ? 1 : -1
            })
        },
        jobAmount: function () {
            return this.visibleItems.length
        }
    }
}
</script>