<template>

    <div class="forms join-form">
        <transition name="slide-left">



            <div v-if="open" class="right-0 bottom-0 top-0 w-full block bg-violet text-black fixed z-101 max-w-[1042px] ">

                <button class="absolute bottom-[16px] md:bottom-auto md:left-auto md:-translate-x-0 left-[50%] translate-x-[-50%] md:top-[24px] z-11 md:right-[24px]" @click="closeForm()">
                    <div class="bg-black text-white body-default uppercase flex space-x-2 px-4 py-2 items-center rounded-32">
                        <span>Close</span>
                        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="6.69727" y="5.49023" width="16" height="1" transform="rotate(45 6.69727 5.49023)" fill="white"/>
                        <rect x="5.99023" y="16.8037" width="16" height="1" transform="rotate(-45 5.99023 16.8037)" fill="white"/>
                        </svg>
                    </div>
                </button>

                <div class="overflow-y-scroll max-h-[100vh] form-scroll pb-32 p-6" id="join-form" v-if="! successMessage">
                    <div class="max-w-[610px]">
                        <h2 class="heading-mobile-l lg:heading-l mb-4 uppercase"><span class="font-pixel">{{ title }}</span></h2>
                        <div class="body-default-mobile md:body-default mb-12">
                            <slot></slot>
                        </div>
                    </div>
                    <div class="join-form-section mb-12">
                        <h3 class="heading-mobile-s lg:heading-s uppercase mb-4 lg:mb-6">Personal <span class="font-pixel">info</span></h3>
                        <div class="flex md:-mx-3 flex-wrap">
                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">
                                <input-field
                                label="First name"
                                name="firstName"
                                type="text"
                                required="true"
                                :class="{ 'error' : errors.firstName }"
                                v-model="firstName"
                                @focus="highlightedSection = 'first'"
                                >
                                </input-field>
                            </div>
                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">
                                <input-field
                                label="Last name"
                                name="lastName"
                                type="text"
                                required="true"
                                :class="{ 'error' : errors.lastName }"
                                v-model="lastName"
                                @focus="highlightedSection = 'first'"
                                >
                                </input-field>
                            </div>
                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">
                                <input-field
                                label="Email"
                                name="email"
                                type="text"
                                required="true"
                                :class="{ 'error' : errors.email }"
                                v-model="email"
                                @focus="highlightedSection = 'first'"
                                >
                                </input-field>
                            </div>

                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">
                                <input-field
                                label="Phone"
                                name="phone"
                                type="text"
                                v-model="phone"
                                @focus="highlightedSection = 'first'"
                                >
                                </input-field>
                            </div>
                        </div>
                    </div>
                    <div class="join-form-section mb-12">
                        <h3 class="heading-mobile-s lg:heading-s uppercase mb-4 lg:mb-6">Handy <span class="font-pixel">links</span></h3>
                        <div class="flex md:-mx-3 flex-wrap">

                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">

                                <input-field
                                label="LinkedIn profile link"
                                name="linkedin"
                                type="text"
                                required="true"
                                :class="{ 'error' : errors.linkedin }"
                                v-model="linkedin"
                                @focus="highlightedSection = 'second'"
                                >
                                </input-field>

                            </div>

                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">

                                <input-field
                                label="Portfolio or website link"
                                name="portfolio"
                                type="text"
                                v-model="portfolio"
                                @focus="highlightedSection = 'second'"
                                >
                                </input-field>

                            </div>

                        </div>
                    </div>
                    <div class="join-form-section">
                        <h3 class="heading-mobile-s lg:heading-s uppercase mb-4 lg:mb-6" v-html="dreamsLabel"></h3>
                        <input-field
                        :label="dreamsPlaceholder"
                        name="dreams"
                        type="textarea"
                        v-model="dreams"
                        @focus="highlightedSection = 'fourth'"

                        >
                        </input-field>
                    </div>
                    <div v-if="tags.length > 0" class="join-form-section mt-12">
                        <h3 class="heading-mobile-s lg:heading-s uppercase mb-4 lg:mb-6">I'm <span class="font-pixel">interested</span> in</h3>
                        <div v-if="tags.length > 0" class="flex flex-wrap">
                            <a v-for="tag, idx in tags" :key="idx" href="javascript:;" :class="[{active: isTagSelected(tag.name)}]" @click="selectTag(tag.name)" class="label mr-2 mb-2 uppercase border-label"><span class="z-10 relative">{{ tag.name }}</span></a>
                        </div>
                    </div>
                    <div v-if="showOpenTo" class="join-form-section mt-12">
                        <h3 class="mobile-heading-s lg:heading-s uppercase mb-4 lg:mb-6">I'm <span class="font-pixel">open</span> to</h3>
                        <div class="flex flex-wrap">
                            <a href="javascript:;" :class="[{active: isTagSelected('Freelance')}]" @click="selectTag('Freelance')" class="label mr-2 mb-2 uppercase border-label"><span class="z-10 relative">Freelance</span></a>
                            <a href="javascript:;" :class="[{active: isTagSelected('Permanent')}]" @click="selectTag('Permanent')" class="label mr-2 mb-2 uppercase border-label"><span class="z-10 relative">Permanent</span></a>
                        </div>
                    </div>
                    <div class="join-form-section mt-12">
                        <input-field
                        label=""
                        required="true"
                        name="policy"
                        type="checkbox"
                        :class="{ 'error' : errors.policy }"
                        v-model="policy"
                        >
                            <span class="text-black body-default">I allow TREK to save my personal data in accordance with their <a href="/privacy/" class="underline" target="_blank">privacy policy</a>.</span>
                        </input-field>
                    </div>
                    <div class="pt-6 pb-4">
                        <button :disabled="apiLoading" :class="{ 'opacity-50 cursor-wait': apiLoading }" @click="checkForm" class="btn btn-black bg-black text-white inline-block group w-full">
                            <span class="px-4 w-full absolute top-[5px] group-hover:block hidden">Join</span>
                            <span class="px-4">Join</span>
                            <span class="px-4 w-full absolute bottom-[5px] group-hover:block hidden">Join</span>
                        </button>
                    </div>
                    <p class="text-10">TREK recognizes the importance of protecting the privacy of your personal data. Trust and openness is at the foundation of everything we do. Technical and organizational safety measures have been set in place to secure the safety of your personal data registered with TREK. You can at anytime exercise your right of access, rectification or erasure by contacting us at community@trek.se</p>
                </div>

                <div class="overflow-y-scroll js-success-join py-10 max-h-[100vh] form-scroll px-5 md:px-8 lg:px-16 text-darkness" id="join-form-complete" v-if="successMessage">
                    <h2 class="heading-s">Thank you!</h2>
                    <div class="body max-w-[610px] mb-12">
                        <span v-html="successText"></span>
                        <div class="mt-8"><a class="btn purple full-rounded btn-rotate large-rotate inline-block medium" href="javascript:;" @click="closeForm()">Close!</a></div>
                    </div>
                </div>

            </div>

        </transition>
        <div class="join-overlay fixed inset-0 h-full bg-charcoal opacity-50 z-100" @click="closeForm()" v-if="open"></div>
    </div>

</template>

<script>
import InputField from './InputField.vue';

    export default {

        components: { InputField },

        props: ['tags', 'position', 'jobId', 'title', 'showOpenTo', 'successText', 'dreamsLabel', 'dreamsPlaceholder'],

        created() {
            window.emitter.on('join-form.open', () => this.open = true)
        },

        data: () => ({
            open: false,
            selectedTags: [],
            apiPath: '/wp-json/trib-form-api/post',
            errors: [],
            email: '',
            firstName: '',
            lastName: '',
            highlightedSection: '',
            phone: '',
            linkedin: '',
            portfolio: '',
            dreams: '',
            freelance: '',
            permanent: '',
            policy: '',
            successMessage: '',
            errorMessage: '',
            apiLoading: false
        }),
        methods: {
            closeForm() {
                this.open = false
                return document.body.classList.remove('overflow-hidden')
            },
            isTagSelected: function (filter) {
                return this.selectedTags.includes(filter)
            },
            selectTag: function (filter) {
                console.log(filter);
                this.highlightedSection = 'fifth'
                const index = this.selectedTags.indexOf(filter);
                if (index > -1) { // If the specified filter already exists in the filters array, we should remove it.
                    this.selectedTags.splice(index)
                }
                else { // Otherwise we append it to the filtered list.
                    this.selectedTags.push(filter);
                }
            },
            selectInterestTag: function (filter) {
                this.highlightedSection = 'sixth'
                const index = this.selectedTags.indexOf(filter);
                if (index > -1) { // If the specified filter already exists in the filters array, we should remove it.
                    this.selectedTags.splice(index)
                }
                else { // Otherwise we append it to the filtered list.
                    this.selectedTags.push(filter);
                }
            },
            checkForm(e) {

                this.errors = {}

                if (! this.firstName) {
                    this.errors.firstName = 'error'
                }

                if (! this.lastName) {
                    this.errors.lastName = 'error'
                }

                if (! this.email) {
                    this.errors.email = 'error'
                }

                if (! this.linkedin) {
                    this.errors.linkedin = 'error'
                }

                if (! this.policy) {
                    this.errors.policy = 'error'
                }

                // Om this.errors har nycklar, dvs. ej tomt objekt, så har vi errors och bryter här
                if (Object.keys(this.errors).length) {
                    return false;
                }

                this.postForm(e);

                e.preventDefault();
            },
            postForm(e) {
                e.preventDefault();

                this.apiLoading = true;
                axios.post(this.apiPath, {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phone: this.phone,
                    linkedin: this.linkedin,
                    portfolio: this.portfolio,
                    dreams: this.dreams,
                    position: this.position,
                    jobId: this.jobId,
                    selectedTags: this.selectedTags,
                    freelance: this.freelance,
                    permanent: this.permanent
                })
                .then( (response) => {
                  console.log(response);
                  this.successMessage = 'Thank you! We will get in touch with you shortly.';
                  this.apiLoading = false
                })
                .catch(() => {this.errorMessage = 'Something went wrong, please try again.', this.apiLoading = false})
            }
        },

    }

</script>
